import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e2c8b92 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _b403d848 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _1e7e53a5 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _5463062c = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _679ab45b = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _27394baa = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _16d116ca = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _1d99c290 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _5aa30949 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _61c2d368 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _4e2ad0a5 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _2aedd873 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _3678d710 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _6bd2e590 = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _4d600d88 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _8ab2d64a = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _2658de11 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _61b12222 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _6daa742c = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _43c89dbb = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _f495182e = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _0f823086 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _15c44bf5 = () => interopDefault(import('../pages/onboarding/confirmation.vue' /* webpackChunkName: "pages/onboarding/confirmation" */))
const _48c5685f = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _4b90b29e = () => interopDefault(import('../pages/onboarding/enhanced-quotes.vue' /* webpackChunkName: "pages/onboarding/enhanced-quotes" */))
const _03fb8a78 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _055762d9 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _5e2442d8 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _370de51a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/index" */))
const _7e9970ce = () => interopDefault(import('../pages/onboarding/money-purchase-annual-allowance.vue' /* webpackChunkName: "pages/onboarding/money-purchase-annual-allowance" */))
const _5d9c944a = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _4380e25e = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _d295299a = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _1cbf8b44 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _33015bac = () => interopDefault(import('../pages/onboarding/pension-savings.vue' /* webpackChunkName: "pages/onboarding/pension-savings" */))
const _6a0a3a8b = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _73e4613d = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _6152ed19 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _9bf23f1a = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _40fcb414 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _71d2ba2c = () => interopDefault(import('../pages/onboarding/tapered-allowance.vue' /* webpackChunkName: "pages/onboarding/tapered-allowance" */))
const _28c01448 = () => interopDefault(import('../pages/onboarding/tax-charge-period.vue' /* webpackChunkName: "pages/onboarding/tax-charge-period" */))
const _08aae38e = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _4620c942 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _06e65069 = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _28cb05f4 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _133b11d8 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/index" */))
const _bb530880 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/index" */))
const _02ba7469 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/index.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/index" */))
const _305d2a74 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bathing-and-showering.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bathing-and-showering" */))
const _5172c856 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bladder-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bladder-control" */))
const _6129c77a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/bowel-control.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/bowel-control" */))
const _64c7d679 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/dressing.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/dressing" */))
const _11868846 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/feeding.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/feeding" */))
const _0d841b0c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/mobility-indoors.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/mobility-indoors" */))
const _0d48b654 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/progression-over-time.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/progression-over-time" */))
const _3aff7e86 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/review" */))
const _46bb19c9 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/ADL/transferring.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/ADL/transferring" */))
const _714fce7c = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/alcohol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/alcohol" */))
const _ec2d3954 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/general" */))
const _0d8c0f5a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/review" */))
const _64493eda = () => interopDefault(import('../pages/onboarding/medical-questionnaire/lifestyle/smoking.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/lifestyle/smoking" */))
const _c8dae20e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/cancer.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/cancer" */))
const _7b5f84bc = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/diabetes.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/diabetes" */))
const _6131d5bf = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/general.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/general" */))
const _be1a06c8 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/high-cholesterol" */))
const _1a36edc2 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/hypertension.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/hypertension" */))
const _2ac0345a = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis" */))
const _17bb6407 = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/neurological.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/neurological" */))
const _8cb1a85e = () => interopDefault(import('../pages/onboarding/medical-questionnaire/medical-assessment/review.vue' /* webpackChunkName: "pages/onboarding/medical-questionnaire/medical-assessment/review" */))
const _a5c9777e = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _3676119e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _4e2c8b92,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _b403d848,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _1e7e53a5,
    name: "help"
  }, {
    path: "/verify-email",
    component: _5463062c,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _679ab45b,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _27394baa,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _16d116ca,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _1d99c290,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _5aa30949,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _61c2d368,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _4e2ad0a5,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _2aedd873,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _3678d710,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _6bd2e590,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _4d600d88,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _8ab2d64a,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _2658de11,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _61b12222,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _6daa742c,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _43c89dbb,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _f495182e,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _0f823086,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/confirmation",
    component: _15c44bf5,
    name: "onboarding-confirmation"
  }, {
    path: "/onboarding/employment-info",
    component: _48c5685f,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/enhanced-quotes",
    component: _4b90b29e,
    name: "onboarding-enhanced-quotes"
  }, {
    path: "/onboarding/fund-selection",
    component: _03fb8a78,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _055762d9,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _5e2442d8,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/medical-questionnaire",
    component: _370de51a,
    name: "onboarding-medical-questionnaire"
  }, {
    path: "/onboarding/money-purchase-annual-allowance",
    component: _7e9970ce,
    name: "onboarding-money-purchase-annual-allowance"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _5d9c944a,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _4380e25e,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _d295299a,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _1cbf8b44,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/pension-savings",
    component: _33015bac,
    name: "onboarding-pension-savings"
  }, {
    path: "/onboarding/personal-info",
    component: _6a0a3a8b,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _73e4613d,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _6152ed19,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _9bf23f1a,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _40fcb414,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tapered-allowance",
    component: _71d2ba2c,
    name: "onboarding-tapered-allowance"
  }, {
    path: "/onboarding/tax-charge-period",
    component: _28c01448,
    name: "onboarding-tax-charge-period"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _08aae38e,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _4620c942,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _06e65069,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _28cb05f4,
    name: "personal-retirement"
  }, {
    path: "/onboarding/medical-questionnaire/ADL",
    component: _133b11d8,
    name: "onboarding-medical-questionnaire-ADL"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle",
    component: _bb530880,
    name: "onboarding-medical-questionnaire-lifestyle"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment",
    component: _02ba7469,
    name: "onboarding-medical-questionnaire-medical-assessment"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bathing-and-showering",
    component: _305d2a74,
    name: "onboarding-medical-questionnaire-ADL-bathing-and-showering"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bladder-control",
    component: _5172c856,
    name: "onboarding-medical-questionnaire-ADL-bladder-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/bowel-control",
    component: _6129c77a,
    name: "onboarding-medical-questionnaire-ADL-bowel-control"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/dressing",
    component: _64c7d679,
    name: "onboarding-medical-questionnaire-ADL-dressing"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/feeding",
    component: _11868846,
    name: "onboarding-medical-questionnaire-ADL-feeding"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/mobility-indoors",
    component: _0d841b0c,
    name: "onboarding-medical-questionnaire-ADL-mobility-indoors"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/progression-over-time",
    component: _0d48b654,
    name: "onboarding-medical-questionnaire-ADL-progression-over-time"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/review",
    component: _3aff7e86,
    name: "onboarding-medical-questionnaire-ADL-review"
  }, {
    path: "/onboarding/medical-questionnaire/ADL/transferring",
    component: _46bb19c9,
    name: "onboarding-medical-questionnaire-ADL-transferring"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/alcohol",
    component: _714fce7c,
    name: "onboarding-medical-questionnaire-lifestyle-alcohol"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/general",
    component: _ec2d3954,
    name: "onboarding-medical-questionnaire-lifestyle-general"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/review",
    component: _0d8c0f5a,
    name: "onboarding-medical-questionnaire-lifestyle-review"
  }, {
    path: "/onboarding/medical-questionnaire/lifestyle/smoking",
    component: _64493eda,
    name: "onboarding-medical-questionnaire-lifestyle-smoking"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/cancer",
    component: _c8dae20e,
    name: "onboarding-medical-questionnaire-medical-assessment-cancer"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/diabetes",
    component: _7b5f84bc,
    name: "onboarding-medical-questionnaire-medical-assessment-diabetes"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/general",
    component: _6131d5bf,
    name: "onboarding-medical-questionnaire-medical-assessment-general"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/high-cholesterol",
    component: _be1a06c8,
    name: "onboarding-medical-questionnaire-medical-assessment-high-cholesterol"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/hypertension",
    component: _1a36edc2,
    name: "onboarding-medical-questionnaire-medical-assessment-hypertension"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/multiple-sclerosis",
    component: _2ac0345a,
    name: "onboarding-medical-questionnaire-medical-assessment-multiple-sclerosis"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/neurological",
    component: _17bb6407,
    name: "onboarding-medical-questionnaire-medical-assessment-neurological"
  }, {
    path: "/onboarding/medical-questionnaire/medical-assessment/review",
    component: _8cb1a85e,
    name: "onboarding-medical-questionnaire-medical-assessment-review"
  }, {
    path: "/blog/:slug?",
    component: _a5c9777e,
    name: "blog-slug"
  }, {
    path: "/",
    component: _3676119e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
